import {round, roundBy1Decimal} from "../util/math";

export type WeatherCondition = {
    details: string,
    key: string,
    weatherType?: WeatherType
}

export enum WeatherType {
    PLAY = 'Play',
    NO_PLAY = 'No_Play',
    COULD_PLAY = 'Could_Play'
}

const rainExpectationText = (rainInMm: number, rainProbabilityInPercentage: number) => `Regenkans: ${rainProbabilityInPercentage} %`;
const windExpectationText = (windSpeedInKmH: number) => `Windsnelheid: ${windSpeedInKmH} km/h`;
const apparentTemperatureText = (apparentTemperatureInCelcius: number) => `Gevoelstemperatuur: ${apparentTemperatureInCelcius} °C`;

export function calculatePadelWeatherConditions(rainInMm: number, rainProbabilityInPercentage: number, windSpeedInKmH: number, apparentTemperatureInCelcius: number): WeatherCondition[] {
    const weatherConditions: WeatherCondition[] = [];

    // rain conditions
    const rainCondition: WeatherCondition = {
        details: rainExpectationText(roundBy1Decimal(rainInMm), rainProbabilityInPercentage),
        key: '001',
        weatherType: WeatherType.PLAY
    }
    if (rainInMm >= 0 && rainProbabilityInPercentage >= 15) {
        rainCondition.weatherType = WeatherType.COULD_PLAY;
    }
    if (rainInMm >= 0.3 && rainProbabilityInPercentage < 15) {
        rainCondition.weatherType = WeatherType.COULD_PLAY;
    }
    if (rainInMm > 0 && rainInMm < 0.3 && rainProbabilityInPercentage >= 60) {
        rainCondition.weatherType = WeatherType.NO_PLAY;
    }
    if (rainInMm >= 0.3 && rainProbabilityInPercentage >= 15) {
        rainCondition.weatherType = WeatherType.NO_PLAY;
    }
    weatherConditions.push(rainCondition);

    // wind conditions
    const windCondition: WeatherCondition = {
        details: windExpectationText(round(windSpeedInKmH)),
        key: '002',
        weatherType: WeatherType.PLAY
    }
    if (windSpeedInKmH > 15 && windSpeedInKmH < 25) {
        windCondition.weatherType = WeatherType.COULD_PLAY;
    }
    if (windSpeedInKmH >= 25) {
        windCondition.weatherType = WeatherType.NO_PLAY;
    }
    weatherConditions.push(windCondition);

    // apparent temperature conditions
    const apparentTemperatureCondition: WeatherCondition = {
        details: apparentTemperatureText(apparentTemperatureInCelcius),
        key: '003',
        weatherType: WeatherType.PLAY
    }
    if (apparentTemperatureInCelcius >= 30 && apparentTemperatureInCelcius < 35) {
        apparentTemperatureCondition.weatherType = WeatherType.COULD_PLAY;
    }
    if (apparentTemperatureInCelcius >= 35) {
        apparentTemperatureCondition.weatherType = WeatherType.NO_PLAY;
    }
    weatherConditions.push(apparentTemperatureCondition);

    return weatherConditions;
}
