import React, {useEffect, useState} from "react";
import {Box, Grid, SvgIcon, Tooltip} from "@mui/material";
import GeoCitiesAutocomplete, {GeoCityDetailsOption} from "./GeoCitiesAutocomplete";
import {ReactComponent as CurrentLocationIcon} from '../assets/icons/location/current-location-02.svg';
import {ReactComponent as InfoRoundedIcon} from '../assets/icons/icons8-info-03.svg';
import {fetchCityNameByGeoCoordinates, getCurrentPosition} from "../services/reverse-geocoding-api";

type ComponentProps = {
    onLocationChanged: (geoCityItem: GeoCityDetailsOption | null) => void;
};

export function LocationFinder(props: ComponentProps) {
    const [latitude, setLatitude] = useState<number | null>(null);
    const [longitude, setLongitude] = useState<number | null>(null);
    const [userLocationLatitude, setUserLocationLatitude] = useState<number | null>(null);
    const [userLocationLongitude, setUserLocationLongitude] = useState<number | null>(null);
    const [userLocationCityName, setUserLocationCityName] = useState<string>('');
    const [selectedCityName, setSelectedCityName] = useState<string>('');

    const cityNameDisplayed: string = selectedCityName && (selectedCityName !== '') ? selectedCityName : userLocationCityName;
    const clearedCityValue: boolean = !selectedCityName || (selectedCityName === '');

    const updateCurrentUserLocation = () => {
        const updateCurrentPosition = async () => {
            if (!clearedCityValue) {
//                console.log('!!! DO NOT updateCurrentPosition...', selectedCityName);
                return null;
            }
//            console.log('!!! updateCurrentPosition...cityName: ', selectedCityName);
            try {
                const position: GeolocationPosition = await getCurrentPosition();
                const {latitude, longitude} = position.coords;
                // const result = await fetchCityNameByGeoCoordinates(latitude, longitude);
                setUserLocationLatitude(latitude);
                setUserLocationLongitude(longitude);
                setLatitude(latitude);
                setLongitude(longitude);
                // setUserLocationCityName(result.city);
            } catch (error) {
                // handle error
                console.log('Error while getting user location: ', error);
            }
        }
        updateCurrentPosition();
    }

    useEffect(() => {
        const fetchReverseGeoCodeCall = async () => {
//            console.log('!!! user location latitude / longitude: ', userLocationLatitude);
            if (userLocationLatitude != null && userLocationLongitude != null) {
                const result = await fetchCityNameByGeoCoordinates(userLocationLatitude, userLocationLongitude);
                setUserLocationCityName(result.city);
                setLatitude(userLocationLatitude);
                setLongitude(userLocationLongitude);
            }
        }
        fetchReverseGeoCodeCall();
    }, [userLocationLatitude, userLocationLongitude]);

    useEffect(updateCurrentUserLocation, [clearedCityValue]);

    useEffect(() => {
        // console.log('!! cityNameDisplayed: ', cityNameDisplayed);
        // console.log('!! latitude: ', latitude);
        if (latitude != null && longitude != null) {
//            console.log('!!! change forecast');
            props.onLocationChanged({
                city: cityNameDisplayed,
                timezone: 'auto',
                key: 'geo',
                latitude: latitude,
                longitude: longitude,
                id: 1,
                country: ''
            })
        }
    }, [latitude, longitude])

    const clearCitySelection = () => {
        setSelectedCityName('');
        // console.log('!!! selectedCityName: ', selectedCityName);
    }

    const onCurrentLocationClicked = () => {
        clearCitySelection();
    }

    return (
        <Grid container direction="column" spacing="0">
            <Grid container item xs="auto" columnSpacing="10">
                <Grid item xs="auto">
                    <GeoCitiesAutocomplete clearValue={clearedCityValue} onCitySelect={(selectedCityItem) => {
                        const selectedCityItemCityName = selectedCityItem?.city ?? null;
                        // console.log('!!!! change: ', selectedCityItemCityName);
                        setLatitude(selectedCityItem?.latitude ?? null);
                        setLongitude(selectedCityItem?.longitude ?? null);
                        // setSelectedCityName(selectedCityItem?.city ?? '');
                        if (selectedCityItemCityName == null) {
                            clearCitySelection();
                        } else {
                            setSelectedCityName(selectedCityItem?.city ?? '')
                        }
                        //props.onLocationChanged(selectedCityItem);
                    }}/>
                </Grid>
                <Grid item xs="auto" alignSelf="center">
                    <Tooltip title={'Huidige locatie'}>
                        <SvgIcon component={CurrentLocationIcon} inheritViewBox fontSize="large"
                                 onClick={onCurrentLocationClicked}/>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container item xs="auto" direction="row" justifyContent="left">
                <Grid container item xs="auto">
                    <Grid item xs="auto" alignSelf="start" sx={{marginTop: '0', marginBottom: '0'}}>
                        {/*<Box sx={{marginTop: '0', marginBottom: '0'}}>*/}
                        <h3>{cityNameDisplayed}</h3>
                        {/*</Box>*/}
                    </Grid>
                </Grid>
                <Grid item xs="auto">
                    {cityNameDisplayed && cityNameDisplayed !== '' && (
                        <Tooltip title={`Breedtegraad: ${latitude}, Lengtegraad: ${longitude}`}>
                            <SvgIcon component={InfoRoundedIcon} inheritViewBox
                                     sx={{marginTop: '5px', width: '18px', height: '18px'}}/>
                        </Tooltip>
                    )}
                </Grid>


                {/*<Grid container item xs="auto" columnSpacing="10">*/}
                {/*    <Grid item xs="auto">*/}
                {/*        <TextField sx={{width: '10ch'}} id="latitudeInputMui" label="Breedtegraad (latitude)"*/}
                {/*                   value={latitude || ''} variant="standard"*/}
                {/*                   InputLabelProps={{shrink: true, "aria-readonly": true}}/>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs="auto">*/}
                {/*        <TextField sx={{width: '10ch'}} id="longitudeInputMui" label="Lengtegraad (longitude)"*/}
                {/*                   value={longitude || ''} variant="standard"*/}
                {/*                   InputLabelProps={{shrink: true, "aria-readonly": true}}/>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}

                {/*</Grid>*/}
                {/*<Grid item xs="auto">*/}
                {/*    <TextField sx={{width: '10ch'}} id="latitudeInputMui" label="Breedtegraad (latitude)"*/}
                {/*               value={latitude || ''} variant="standard"*/}
                {/*               InputLabelProps={{shrink: true, "aria-readonly": true}}/>*/}
                {/*</Grid>*/}
                {/*<Grid item xs="auto">*/}
                {/*    <TextField sx={{width: '10ch'}} id="longitudeInputMui" label="Lengtegraad (longitude)"*/}
                {/*               value={longitude || ''} variant="standard"*/}
                {/*               InputLabelProps={{shrink: true, "aria-readonly": true}}/>*/}
                {/*</Grid>*/}
            </Grid>
        </Grid>
    )
        ;
}
