export function round(input: number) {
    return Math.round(input);
}

export function roundBy1Decimal(input: number) {
    return Math.round(input * 10) / 10;
}

export function roundBy2Decimals(input: number) {
    return Math.round(input * 100) / 100;
}
