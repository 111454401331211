import {Autocomplete, TextField} from "@mui/material";
import * as React from "react";
import {useEffect} from "react";

type ForecastDatesComboBoxProps = {
    onDateSelect: (forecastDateOption: ForecastDateOption | null) => void;
}

export type ForecastDateOption = {
    displayDate: string,
    isoDate: string,
    date: Date
}

/**
 * This component is based on https://mui.com/material-ui/react-autocomplete/.
 */
export default function ForecastDatesComboBox(props: ForecastDatesComboBoxProps) {
    const maxNrOfDaysForecast = 15;
    const defaultForecastDateOption = createForecastDateOption(new Date());

    const [value, setValue] = React.useState<ForecastDateOption | null>(defaultForecastDateOption);

    useEffect(() => {
        props.onDateSelect(value);
    }, []);

    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            value={value}
            options={forecastDates(maxNrOfDaysForecast)}
            isOptionEqualToValue={(option, value) => option.isoDate == value.isoDate}
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.displayDate
            }
            getOptionKey={option => option.isoDate}
            sx={{width: 275}}
            renderInput={(params) => <TextField {...params} label="Datum" variant="outlined"/>}
            onChange={(event: any, selectedValue: ForecastDateOption | null) => {
                //console.log('!!! change: ', selectedValue);
                if (selectedValue === null) {
                    selectedValue = defaultForecastDateOption;
                }
                setValue(selectedValue);
                //console.log('!!! setValue: ', selectedValue);
                props.onDateSelect(selectedValue);
            }}
        />
    );
}

const forecastDates = (nrOfDates: number): ForecastDateOption[] => {
    const today = new Date();
    const consecutiveDates = [today];

    for (let i = 1; i < nrOfDates - 1; i++) {
        const nextDayDate = new Date();
        nextDayDate.setDate((today.getDate() + i))
        consecutiveDates.push(nextDayDate);
    }

    return consecutiveDates.map(date => createForecastDateOption(date));
}

const createForecastDateOption = (date: Date): ForecastDateOption => (
    {
        displayDate: date.toLocaleDateString('nl-NL', {
            day: 'numeric', month: 'long', year: 'numeric', weekday: 'long'
        }),
        isoDate: date.toJSON().slice(0, 10),
        date: date
    }
)
