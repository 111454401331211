import React, {useEffect, useState} from 'react';
import './App.css';
import background_portrait_xsmall from './assets/images/tennis-paddles-balls-arrangement_portrait_332w.jpg';
import background_portrait_small from "./assets/images/tennis-paddles-balls-arrangement_portrait_446w.jpg";
import background_750w from "./assets/images/tennis-paddles-balls-arrangement_01_750w.jpg";
import background_large from "./assets/images/tennis-paddles-balls-arrangement_01.jpg";
import new_background_large from "./assets/images/high-angle-palette-balls-field-2832-4240.jpg"
import {defaultParams, fetchWeatherForecast} from "./services/weatherforecast-api";
import {addHours, format} from 'date-fns';
import {GeoCityDetailsOption} from "./components/GeoCitiesAutocomplete";
import {ForecastDateOption} from "./components/ForecastDatesCombobox";
import Grid from '@mui/material/Grid';
import {Box, createTheme, Paper, ThemeProvider} from "@mui/material";
import {styled} from '@mui/material/styles';
import WeatherDataResultsTable from "./components/WeatherDataResultsTable";
import SearchInputComponent from "./components/SearchInputComponent";

export type ForecastDate = ForecastDateOption | null;

const theme = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    // Custom styles here
                    padding: '10px',
                    backgroundColor: '#eaeff1',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    // Outlined
                    "& .MuiOutlinedInput-root": {
                        color: "#000",
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#2e2e2e",
                            borderWidth: "2px",
                        },
                        "&.Mui-focused": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "secondary.main",
                                borderWidth: "3px",
                            },
                        },
                        "&:hover:not(.Mui-focused)": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#ccc",
                            },
                        },
                    },
                    "& .MuiInputLabel-outlined": {
                        color: "#2e2e2e",
                        fontWeight: "bold",
                        "&.Mui-focused": {
                            color: "secondary.main",
                        },
                    },
                    // Filled
                    "& .MuiFilledInput-root": {
                        color: "#000",
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        backgroundColor: "#e7e7e7",
                        borderTopLeftRadius: "7px",
                        borderTopRightRadius: "7px",
                        "&:before": {
                            borderColor: "#2e2e2e",
                            borderWidth: "2px",
                        },
                        "&:after": {
                            borderColor: "secondary.main",
                            borderWidth: "3px",
                        },
                        ":hover:not(.Mui-focused)": {
                            "&:before": {
                                borderColor: "#e7e7e7",
                                borderWidth: "2px",
                            },
                            backgroundColor: "#f4f4f4",
                        },
                    },
                    "& .MuiInputLabel-filled": {
                        color: "#2e2e2e",
                        fontWeight: "bold",
                        "&.Mui-focused": {
                            color: "secondary.main",
                        },
                    },
                    // Standard
                    "& .MuiInput-root": {
                        color: "#000",
                        fontFamily: "Arial",
                        fontWeight: "normal",
                        "&:before": {
                            borderColor: "#2e2e2e",
                            borderWidth: "2px",
                        },
                        "&:after": {
                            borderColor: "secondary.main",
                            borderWidth: "3px",
                        },
                        ":hover:not(.Mui-focused)": {
                            "&:before": {
                                borderColor: "#e7e7e7",
                                borderWidth: "2px",
                            },
                        },
                    },
                },
            },
        },
    },
});

function App() {
    const [weatherData, setWeatherData] = useState<any>({});
    const [date, setDate] = useState<ForecastDate>(null);
    const [geoCity, setGeoCity] = useState<GeoCityDetailsOption | null>(null);


    useEffect(() => {
        if (date && geoCity) {
            updateWeatherForecastData();
        }
    }, [date, geoCity]);

    // const handleClick = async () => {
    //     updateWeatherForecastData();
    // }

    const updateWeatherForecastData = async () => {
        const startHour = `${date?.isoDate}T08:00`;
        const endHour = format(addHours(startHour, 15), "yyyy-MM-dd'T'kk:mm");
        let weatherForecastData = await fetchWeatherForecast({
            ...defaultParams,
            start_hour: startHour,
            end_hour: endHour,
            latitude: geoCity?.latitude ? geoCity.latitude : 0,
            longitude: geoCity?.longitude ? geoCity.longitude : 0,
            timezone: geoCity?.timezone ? geoCity?.timezone : 'Europe/Berlin'
        });
        setWeatherData(weatherForecastData);
    }

    const handleForecastDateSelected = (forecastDateOption: ForecastDate) => {
        if (forecastDateOption != null) {
            setDate(forecastDateOption);
        }
    }

    // const Item = styled(Paper)(({theme}) => ({
    //     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    //     ...theme.typography.body2,
    //     padding: theme.spacing(1),
    //     textAlign: 'center',
    //     color: theme.palette.text.secondary,
    // }));

    return (
        <ThemeProvider theme={theme}>
            <Box className="wrapper">
                <div className="background-image-bottom"></div>
                <Box className="App">
                    {/*<picture className="fullscreen">*/}
                    {/*    /!*<source media="(min-width: 768px)" srcSet={background_750w} sizes="100vw"/>*!/*/}
                    {/*    /!*<source media="(max-width: 320px)" srcSet={background_portrait_xsmall} sizes="106px"/>*!/*/}
                    {/*    <source media="(min-width: 321px)"*/}
                    {/*            srcSet={background_portrait_small + ' 320w, ' + background_750w + ' 750w, ' + background_large + ' 1280w'}*/}
                    {/*            sizes="(max-width: 321px) 100vw, (max-width: 1280px) 100vw, 1280px"*/}
                    {/*        // sizes="(max-width: 320px) 320px, (max-width: 750px) 750px, 1280px"*/}
                    {/*    />*/}
                    {/*    /!*<BlurryLoadingImage*!/*/}
                    {/*    /!*    preview={background_portrait_xsmall}*!/*/}
                    {/*    <img src={background_750w}/>*/}
                    {/*</picture>*/}
                    <div className="background-image"></div>
                    <Grid container direction="row" className="main-grid">
                        {/*<div className="background-image-bottom"></div>*/}
                        <Grid item xs={12}>
                            {/*<div className="holder fulscreen" style={{*/}
                            {/*    backgroundImage: `url(${background_large})`,*/}
                            {/*    backgroundRepeat: 'no-repeat',*/}

                            {/*}}>*/}
                            <Grid container direction="row" paddingTop={2}>
                                <Grid item xs={3}>
                                    &nbsp;
                                </Grid>
                                <Grid container item xs="auto" direction="column" alignItems="left">
                                    <Grid item xs="auto">
                                        <SearchInputComponent onDateSelected={handleForecastDateSelected}
                                                              onLocationChanged={geoCityItem => {
                                                                  setGeoCity(geoCityItem);
                                                              }}/>
                                    </Grid>
                                    <Grid item xs="auto" alignSelf="center">
                                        <WeatherDataResultsTable weatherForecastData={weatherData}/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={9}>
                                    &nbsp;
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </ThemeProvider>
    )

    // function logWeatherData() {
    //     for (let i = 0; i < weatherData.hourly.time.length; i++) {
    //         console.log(
    //             weatherData.hourly.time[i].toISOString(),
    //             weatherData.hourly.temperature2m[i],
    //             weatherData.hourly.apparentTemperature[i],
    //             weatherData.hourly.precipitationProbability[i],
    //             weatherData.hourly.precipitation[i],
    //             weatherData.hourly.rain[i],
    //             weatherData.hourly.windSpeed10m[i]
    //         );
    //     }
    // }
}

export default App;
