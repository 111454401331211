import {fetchWeatherApi} from "openmeteo";
import {WeatherApiResponse} from '@openmeteo/sdk/weather-api-response';

export type WeatherForecastParams = {
    latitude: number,
    longitude: number,
    timezone: string,
    start_hour?: string,
    end_hour?: string
}

export type WeatherForecastResponse = {
    latitude: number | null,
    longitude: number | null,
    timezone: string | null,

    hourly: {
        time: Date[],
        temperature2m: Float32Array,
        apparentTemperature: Float32Array,
        precipitationProbability: Float32Array,
        precipitation: Float32Array,
        rain: Float32Array,
        windSpeed10m: Float32Array
    }
}

export const defaultParams: WeatherForecastParams = {
    "latitude": 51.7050303,
    "longitude": 5.3371023,
    //"hourly": ["temperature_2m", "apparent_temperature", "precipitation_probability", "precipitation", "rain", "wind_speed_10m"],
    "timezone": "Europe/Berlin",
    "start_hour": "2024-05-07T09:00",
    "end_hour": "2024-05-08T00:00"
};

export async function fetchWeatherForecast(params: WeatherForecastParams): Promise<WeatherForecastResponse | null> {
    const url = "https://api.open-meteo.com/v1/forecast";
    const hourlyWeatherVariables = ["temperature_2m", "apparent_temperature", "precipitation_probability", "precipitation", "rain", "wind_speed_10m"];
    try {
        const responses = await fetchWeatherApi(url, {...params, hourly: hourlyWeatherVariables});
        // Helper function to form time ranges
        const range = (start: number, stop: number, step: number) =>
            Array.from({length: (stop - start) / step}, (_, i) => start + i * step);

        // Process first location. Add a for-loop for multiple locations or weather models
        const response = responses[0];

        // Attributes for timezone and location
        const utcOffsetSeconds = response.utcOffsetSeconds();
        const timezone = response.timezone();
        //const timezoneAbbreviation = response.timezoneAbbreviation();
        const latitude = response.latitude();
        const longitude = response.longitude();

        const hourlyResults = response.hourly()!;

        // Note: The order of weather variables in the URL query and the indices below need to match!
        return {
            latitude: latitude,
            longitude: longitude,
            timezone: timezone,
            // `weatherData` now contains a simple structure with arrays for datetime and weather data
            hourly: {
                time: range(Number(hourlyResults.time()), Number(hourlyResults.timeEnd()), hourlyResults.interval()).map(
                    (t) => new Date((t + utcOffsetSeconds) * 1000)
                    //(t) => new Date((t + utcOffsetSeconds) * 1000)
                ),
                temperature2m: hourlyResults.variables(0)!.valuesArray()!,
                apparentTemperature: hourlyResults.variables(1)!.valuesArray()!,
                precipitationProbability: hourlyResults.variables(2)!.valuesArray()!,
                precipitation: hourlyResults.variables(3)!.valuesArray()!,
                rain: hourlyResults.variables(4)!.valuesArray()!,
                windSpeed10m: hourlyResults.variables(5)!.valuesArray()!,
            }
        };
    } catch (e) {
        console.log('!!error: ', e);
        return null;
    }
}
