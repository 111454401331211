import * as React from 'react';
import {useEffect, useMemo, useRef} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {debounce} from '@mui/material/utils';
import {fetchGeoCoordinatesByCity} from "../services/geocoding-api";

export type GeoCityDetailsOption = {
    id: number,
    key: string,
    city: string,
    latitude: number,
    longitude: number,
    country: string,
    timezone: string
}

type ComponentProps = {
    clearValue: boolean,
    onCitySelect: (geoCityDetails: GeoCityDetailsOption | null) => void;
}

/**
 * This component is based on https://mui.com/material-ui/react-autocomplete/.
 */
export default function GeoCitiesAutocomplete(props: ComponentProps) {
    const [value, setValue] = React.useState<GeoCityDetailsOption | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<readonly GeoCityDetailsOption[]>([]);

    useEffect(() => {
        if (props.clearValue) {
            setValue(null);
        }
    }, [props.clearValue]);


    const useDebounce = (callback: any) => {
        const ref: any = useRef();

        useEffect(() => {
            ref.current = callback;
        }, [callback]);

        const debouncedCallback = useMemo(() => {
            const func = () => {
                ref.current?.();
            };

            return debounce(func, 400);
        }, []);

        return debouncedCallback;
    };

    const debouncedRequest = useDebounce(() => {
        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetchGeoCoordinatesByCity(inputValue)
            .then((json) => {
                const results = json;
                if (results) {
                    const geoCityItems = results.map(item => ({
                        ...item,
                        key: String(item.id)
                    } as GeoCityDetailsOption));
                    //console.log('!!!! geoCityItems: ', geoCityItems);
                    setOptions([...geoCityItems])
                }
                //console.log('!!!! json: ', json);
            })
            .catch((err) => {
                console.error('!!! error: ', err);
            });

        //console.log(inputValue);
    });

    return (
        <Autocomplete
            id="geo-cities"
            sx={{width: 275}}
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.city
            }
            getOptionKey={option => option.key}
            isOptionEqualToValue={(option, value) => option.id == value.id}
            filterOptions={(x) => x}
            options={options}
            autoComplete={false}
            includeInputInList
            filterSelectedOptions
            value={value}
            inputValue={inputValue}
            blurOnSelect={true}
            clearOnBlur={true}
            noOptionsText="Geen locaties gevonden"
            onChange={(event: any, newValue: GeoCityDetailsOption | null) => {
                //console.log('!!! change: ', newValue);
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                props.onCitySelect(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                //setValue(null);
                setInputValue(newInputValue);
                //console.log('!!! newInputValue: ', newInputValue);
                debouncedRequest();
            }}
            renderInput={(params) => (
                <TextField {...params} label="Kies een locatie..." fullWidth variant="outlined"/>
            )}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.key}>
                        {option.city} ({option.country})
                    </li>
                    // <li {...props} key={option.key}>
                    //     <Grid container alignItems="center">
                    //         <Grid item sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                    //             <Box
                    //                 key={option.key}
                    //                 component="span"
                    //             >
                    //                 {option.name}
                    //             </Box>
                    //         </Grid>
                    //     </Grid>
                    // </li>
                );
            }}
        />
    );
}
