/**
 * The formula for calculating wind on the Beaufort wind force scale is the following: v = 0.836 B^3/2.
 * Where v = is the wind speed in meters per seconds (m/s) at 10 meters above the sea and B is the Beaufort scale number.
 */
export function calculateWindInBeaufort(windSpeedInKmPerHour: number): number {
    const windSpeedInMetersPerSeconds = kmPerHourToMetersPerSecond(windSpeedInKmPerHour);
    return Math.round(Math.cbrt(Math.pow(windSpeedInMetersPerSeconds/0.836, 2)));
}

export function kmPerHourToMetersPerSecond(kmPerHour: number): number {
    return kmPerHour * (1000 / 3600);
}
