import {
    Box,
    Collapse, Divider,
    IconButton, List, ListItem, ListItemIcon, ListItemText,
    Paper,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip
} from "@mui/material";
import {format} from "date-fns";
import {
    calculatePadelWeatherConditions,
    WeatherCondition, WeatherType
} from "../services/padelweatherscore";
import {calculateWindInBeaufort} from "../services/windspeedconverter";
import {round, roundBy1Decimal} from "../util/math";
import {ReactComponent as ClockIcon} from '../assets/icons/time/clock.svg';
import {ReactComponent as TennisBallIcon} from '../assets/icons/sport/tennis-ball.svg';
import {ReactComponent as CheckMarkIcon} from '../assets/icons/checkmark/checkmark-running.svg';
import {ReactComponent as AttentionIcon} from '../assets/icons/attentionmark/exclamation-mark.svg';
import {ReactComponent as CrossMarkIcon} from '../assets/icons/stopmark/prohibited.svg';
import {ReactComponent as TemperatureIcon} from '../assets/icons/weather/temperature-03.svg';
import {ReactComponent as RainIcon} from '../assets/icons/weather/rain.svg';
import {ReactComponent as WindIcon} from '../assets/icons/weather/wind.svg';
import {ReactComponent as InfoRoundedIcon} from '../assets/icons/icons8-info-03.svg';
import {ReactComponent as InfoIcon} from '../assets/icons/info-02.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {TfiAlert} from "react-icons/tfi";
import {RiAlertLine} from "react-icons/ri";
import {GrAlert} from "react-icons/gr";

import React, {Fragment, useState} from "react";
import {WeatherForecastResponse} from "../services/weatherforecast-api";

type ComponentProps = {
    weatherForecastData: WeatherForecastResponse
}

type RowData = {
    id: number,
    timeHourInDayAsString: string,
    padelWeatherScore: WeatherCondition[],
    temperature: number,
    apparentTemperature: number,
    precipitation: number,
    windspeedInBft: number
};

type DetailedRowData = {
    key: string,
    detail: string
}

function WeatherDataRow(props: RowData) {
    const row = props;
    const rowNoPlay: WeatherCondition[] = row.padelWeatherScore.filter(wi => wi.weatherType === WeatherType.NO_PLAY);
    const rowCouldPlay: WeatherCondition[] = row.padelWeatherScore.filter(wi => wi.weatherType === WeatherType.COULD_PLAY);
    const rowPlay: WeatherCondition[] = row.padelWeatherScore.filter(wi => wi.weatherType === WeatherType.PLAY);
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow key={row.id}>
                <TableCell align="center">
                    {row.timeHourInDayAsString}
                </TableCell>
                <TableCell align="center">
                    {rowNoPlay.length == 0 && rowCouldPlay.length == 0 && (
                        <SvgIcon component={CheckMarkIcon} inheritViewBox htmlColor="#2b7a54"
                                 sx={{marginTop: '5px', width: '24px', height: '24px'}}/>
                    )}
                    {rowNoPlay.length > 0 && (
                        <SvgIcon component={CrossMarkIcon} inheritViewBox
                                 sx={{marginTop: '5px', width: '24px', height: '24px'}}/>
                    )}
                    {rowCouldPlay.length > 0 && rowNoPlay.length == 0 && (
                        <SvgIcon component={AttentionIcon} inheritViewBox
                                 sx={{marginTop: '5px', width: '24px', height: '24px'}}/>
                    )}
                </TableCell>
                <TableCell align="right">
                    {row.temperature} °C
                </TableCell>
                {/*<TableCell align="right">*/}
                {/*    {row.apparentTemperature} °C*/}
                {/*</TableCell>*/}
                <TableCell align="right">
                    {Number.parseFloat(row.precipitation.toString()).toFixed(1) + ' mm'}
                </TableCell>
                <TableCell align="right">
                    {row.windspeedInBft} Bft
                </TableCell>
                <TableCell align="center">
                    <IconButton disabled={row.padelWeatherScore.length == 0}
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0, maxWidth: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {/*<Table size="small" aria-label="purchases">*/}
                        {/*    <TableBody>*/}
                        {/*        {row.padelWeatherScore.map((weatherConditionItem) => (*/}
                        {/*            <TableRow key={weatherConditionItem.key}>*/}
                        {/*                <TableCell><SvgIcon component={AttentionIcon} inheritViewBox/></TableCell>*/}
                        {/*                <TableCell>{weatherConditionItem.details}</TableCell>*/}
                        {/*            </TableRow>*/}
                        {/*        ))}*/}
                        {/*    </TableBody>*/}
                        {/*</Table>*/}
                        {/*<ul>*/}
                        {/*    {rowNoPlay.map((weatherConditionItem) => (*/}
                        {/*        <li key={weatherConditionItem.key}>*/}
                        {/*            {weatherConditionItem.details}*/}
                        {/*        </li>*/}
                        {/*    ))}*/}
                        {/*    {rowCouldPlay.map((weatherConditionItem) => (*/}
                        {/*        <li key={weatherConditionItem.key}>*/}
                        {/*            {weatherConditionItem.details}*/}
                        {/*        </li>*/}
                        {/*    ))}*/}
                        {/*    {rowPlay.map((weatherConditionItem) => (*/}
                        {/*        <li key={weatherConditionItem.key}>*/}
                        {/*            {weatherConditionItem.details}*/}
                        {/*        </li>*/}
                        {/*    ))}*/}
                        {/*</ul>*/}

                        <List sx={{width: '100%', maxWidth: 360}}>
                            {rowNoPlay.map((weatherConditionItem) => (
                                <Fragment key={weatherConditionItem.key}>
                                    <ListItem>
                                        <ListItemIcon>
                                            <SvgIcon component={CrossMarkIcon} inheritViewBox/>
                                        </ListItemIcon>
                                        <ListItemText disableTypography={true}
                                                      primary={weatherConditionItem.details}
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li"/>
                                </Fragment>
                            ))}
                            {rowCouldPlay.map((weatherConditionItem) => (
                                <Fragment key={weatherConditionItem.key}>
                                    <ListItem>
                                        <ListItemIcon>
                                            <SvgIcon component={AttentionIcon} inheritViewBox/>
                                        </ListItemIcon>
                                        <ListItemText disableTypography={true}
                                                      primary={weatherConditionItem.details}
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li"/>
                                </Fragment>
                            ))}
                            {rowPlay.map((weatherConditionItem) => (
                                <Fragment key={weatherConditionItem.key}>
                                    <ListItem>
                                        <ListItemIcon>
                                            <SvgIcon component={InfoIcon} inheritViewBox/>
                                        </ListItemIcon>
                                        <ListItemText disableTypography={true}
                                                      primary={weatherConditionItem.details}
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li"/>
                                </Fragment>
                            ))}
                        </List>

                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default function WeatherDataResultsTable(props: ComponentProps) {

    const weatherData: WeatherForecastResponse = props.weatherForecastData;

    return (
        <>
            {weatherData === null && (<span>Geen weerresultaten gevonden</span>)}
            {weatherData != null && weatherData.hourly && (
                //<TableContainer component={Paper} sx={{marginTop: '20px', minWidth: 395, maxWidth: 395}}>
                <TableContainer component={Paper} sx={{marginTop: '20px'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    <Tooltip title={'Tijd'}>
                                        <SvgIcon component={ClockIcon} inheritViewBox/>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="center">
                                    <Tooltip title={'Is het weer om buiten te spelen?'}>
                                        <SvgIcon component={TennisBallIcon} inheritViewBox/>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="center">
                                    <Tooltip title={'Temperatuur in °C'}>
                                        <SvgIcon component={TemperatureIcon}
                                                 inheritViewBox/>
                                    </Tooltip>
                                </TableCell>
                                {/*<TableCell align="center">*/}
                                {/*    <Tooltip title={'Gevoelstemperatuur in °C'}>*/}
                                {/*        <SvgIcon component={TemperatureFeelsLikeIcon}*/}
                                {/*                 inheritViewBox/>*/}
                                {/*    </Tooltip>*/}
                                {/*</TableCell>*/}
                                <TableCell align="center">
                                    <Tooltip title={'Hoeveelheid neerslag in mm'}>
                                        <SvgIcon component={RainIcon}
                                                 inheritViewBox/>
                                    </Tooltip></TableCell>
                                <TableCell align="center">
                                    <Tooltip title={'Windsnelheid in Beaufort'}>
                                        <SvgIcon component={WindIcon}
                                                 inheritViewBox/></Tooltip>
                                </TableCell>
                                <TableCell align="center">
                                    <Tooltip title={'Toelichting indicatie buitenspeelweer'}>
                                        <SvgIcon component={InfoRoundedIcon} inheritViewBox/>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {weatherData.hourly.time.map((weatherDataTime: Date, index: number) => (
                                <WeatherDataRow id={index}
                                                key={index}
                                                timeHourInDayAsString={weatherDataTime.getUTCHours().toString().padStart(2, '0') + ':' + weatherDataTime.getUTCMinutes().toString().padStart(2, '0')}
                                    //padelWeatherScore={calculatePadelWeatherScore(weatherData.hourly.precipitation[index], calculateWindInBeaufort(weatherData.hourly.windSpeed10m[index]))}
                                                padelWeatherScore={calculatePadelWeatherConditions(weatherData.hourly.precipitation[index], weatherData.hourly.precipitationProbability[index], weatherData.hourly.windSpeed10m[index], round(weatherData.hourly.apparentTemperature[index]))}
                                                temperature={round(weatherData.hourly.temperature2m[index])}
                                                apparentTemperature={round(weatherData.hourly.apparentTemperature[index])}
                                                precipitation={roundBy1Decimal(weatherData.hourly.precipitation[index] * 100) / 100}
                                                windspeedInBft={calculateWindInBeaufort(weatherData.hourly.windSpeed10m[index])}
                                />
                            ))}
                        </TableBody>
                        {/*<TableBody>*/}
                        {/*    {weatherData.hourly.time.map((weatherDataTime: Date, index: number) => (*/}
                        {/*        <TableRow key={index}>*/}
                        {/*            <TableCell component="th" scope="row">*/}
                        {/*                {format(weatherDataTime, 'HH:mm')}*/}
                        {/*            </TableCell>*/}
                        {/*            <TableCell>*/}
                        {/*                {calculatePadelWeatherScore(weatherData.hourly.precipitation[index], calculateWindInBeaufort(weatherData.hourly.windSpeed10m[index])) > 5 && (*/}
                        {/*                    <SvgIcon component={CheckMarkIcon} inheritViewBox/>*/}
                        {/*                )}*/}
                        {/*                {calculatePadelWeatherScore(weatherData.hourly.precipitation[index], calculateWindInBeaufort(weatherData.hourly.windSpeed10m[index])) <= 5 && (*/}
                        {/*                    <SvgIcon component={CrossMarkIcon} inheritViewBox/>*/}
                        {/*                )}*/}
                        {/*            </TableCell>*/}
                        {/*            <TableCell>*/}
                        {/*                {round(weatherData.hourly.temperature2m[index])} °C*/}
                        {/*            </TableCell>*/}
                        {/*            <TableCell>*/}
                        {/*                {round(weatherData.hourly.apparentTemperature[index])} °C*/}
                        {/*            </TableCell>*/}
                        {/*            <TableCell>*/}
                        {/*                {roundBy1Decimal(weatherData.hourly.precipitation[index] * 100) / 100} mm*/}
                        {/*                /!*({weatherData.hourly.precipitationProbability[index]} %)*!/*/}
                        {/*            </TableCell>*/}
                        {/*            <TableCell>*/}
                        {/*                {calculateWindInBeaufort(weatherData.hourly.windSpeed10m[index])} Bft*/}
                        {/*                /!*({roundBy1Decimal(weatherData.hourly.windSpeed10m[index])} km/h)*!/*/}
                        {/*            </TableCell>*/}
                        {/*            <TableCell>*/}
                        {/*                {weatherData.hourly.precipitationProbability[index]}*/}
                        {/*            </TableCell>*/}
                        {/*        </TableRow>*/}
                        {/*    ))}*/}
                        {/*</TableBody>*/}
                    </Table>
                </TableContainer>
            )}
        </>
    )
}
