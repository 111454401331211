export type GeoLocationDetails = {
    id: number,
    city: string,
    latitude: number,
    longitude: number,
    country: string,
    timezone: string
}

export async function fetchCityNameByGeoCoordinates(latitude: number, longitude: number): Promise<GeoLocationDetails> {
    const url: string = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`;
    try {
        //const url = `https://geocoding-api.open-meteo.com/v1/search?name=${city}&count=10&language=en`;
        const response = await fetch(url);
        // const json: Promise<any> = response.json();
        //console.log('!!! json: ', json);
        return await response.json().then(value => ({
            id: 1,
            city: value.city,
            latitude: value.latitude,
            longitude: value.longitude,
            country: value.countryName,
            timezone: ''
        }));
    } catch (error) {
        throw new Error('Error while trying to reverse geocode: ', {cause: error});
    }
    //
    //
    // return json.then(value => ({
    //         id: 1,
    //         city: value.city,
    //         latitude: value.latitude,
    //         longitude: value.longitude,
    //         country: value.countryName,
    //         timezone: ''
    //     })
    // );
}

// export function getCurrentPosition(options = {}) {
//     return new Promise((resolve, reject) => {
//         navigator.geolocation.getCurrentPosition(resolve, reject, options);
//     });
// }

export function getCurrentPosition(options?: PositionOptions): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject, options)
    );
}
