export type GeoLocationDetails = {
    id: number,
    city: string,
    latitude: number,
    longitude: number,
    country: string,
    timezone: string
}

export async function fetchGeoCoordinatesByCity(city: string): Promise<GeoLocationDetails[]> {
    const url = `https://geocoding-api.open-meteo.com/v1/search?name=${city}&count=10&language=en`;
    const response = await fetch(url);
    const json = await response.json();
    //console.log('!!! json: ', json);

    // TODO: fix when results is undefined
    return json.results
        .map((item: any) => ({
            id: item.id,
            city: item.name,
            latitude: item.latitude,
            longitude: item.longitude,
            country: item.country,
            timezone: item.timezone
        }))
    //.filter((city: GeoCityDetails) => city.country === 'Netherlands');
}
