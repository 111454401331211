import React from "react";
import Grid from "@mui/material/Grid";
import ForecastDatesComboBox from "./ForecastDatesCombobox";
import {LocationFinder} from "./LocationFinder";
import {ForecastDate} from "../App";
import {GeoCityDetailsOption} from "./GeoCitiesAutocomplete";

type ComponentProps = {
    onDateSelected: (forecastDateOption: ForecastDate | null) => void;
    onLocationChanged: (geoCityItem: GeoCityDetailsOption | null) => void;
}

export default function SearchInputComponent(props: ComponentProps) {

    const handleForecastDateSelected = (forecastDateOption: ForecastDate) => {
        if (forecastDateOption != null) {
            props.onDateSelected(forecastDateOption);
        }
    }

    return (
        <Grid container direction="column" rowSpacing={2} border={0}>
            <Grid item xs="auto">
                <ForecastDatesComboBox onDateSelect={handleForecastDateSelected}/>
            </Grid>
            <Grid item xs="auto">
                <LocationFinder onLocationChanged={props.onLocationChanged}/>
            </Grid>
        </Grid>
    )
}
